/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  deleteHealth,
  addHealth,
  setTrueDefHealth,
  setSelectedHealth,
  updateHealth,
} from '../../../redux/actions/patient.history.action';

function HealthHistory({
  deleteHealth,
  addHealth,
  patientID,
  defaultHealth,
  userHealth,
  setSelectedHealth,
  setTrueDefHealth,
  selectedHealth,
  updateHealth,
  userRef,
}) {
  const [other, setOtherValue] = useState('');

  useEffect(() => {
    // Ejecutar solo si userHealth o defaultHealth cambian significativamente
    if (userHealth.length > 0 && defaultHealth.length > 0) {
      const updatedDefaultHealth = [...defaultHealth]; // Clonar para evitar mutaciones
      userHealth.forEach((actual) => {
        const item = updatedDefaultHealth.find(
          (def) => actual.name === def.name
        );
        if (item) {
          item.checked = true;
          setTrueDefHealth(item);
          if (item.name === 'Otros') {
            setSelectedHealth(actual);
          }
        }
      });
    }
  }, [JSON.stringify(userHealth), JSON.stringify(defaultHealth)]);

  const handleChange = (e) => {
    const tempSelectedItem = defaultHealth.find(
      (item) => item.name === e.target.name
    );

    if (tempSelectedItem) {
      tempSelectedItem.checked = !tempSelectedItem.checked;
      setTrueDefHealth(tempSelectedItem);
      setSelectedHealth(tempSelectedItem);
    }
  };

  const handleUpdate = (e) => {
    // encontrar el indice del state que decidira si se elimina o no un elemento de la base de datos
    const tempSelectedItem = defaultHealth.find(
      (item) => item.name === e.target.name
    );

    if (tempSelectedItem) {
      if (tempSelectedItem.checked === true) {
        if (userRef) {
          addHealth(e.target.name, userRef._id);
        } else {
          addHealth(e.target.name, patientID);
        }
      } else {
        userHealth.forEach((element) => {
          if (element.name === e.target.name) {
            if (e.target.name === 'Otros') {
              setSelectedHealth(null);
            }
            deleteHealth(element._id);
          }
        });
      }
    }
  };
  const handleChangeOther = (e) => {
    setOtherValue(e.target.value);
  };

  const handleUpdateOther = (e) => {
    const tempSelectedItem = userHealth.find(
      (item) => item.name === selectedHealth.name
    );

    if (tempSelectedItem) {
      tempSelectedItem.other.push(other);

      updateHealth(tempSelectedItem._id, tempSelectedItem.other);
    }
    setOtherValue('');
  };

  return (
    <div>
      <div className='sales mt-3 pr-0 pl-0'>
        <div className='media'>
          <div className='media-body'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <h5 className='font-blue mt-2 mb-3 text-center'>
                  Agregar Antecedentes de Salud
                </h5>
              </div>

              <ul className='list-group list-group-flush'>
                {defaultHealth.map((health) => (
                  <>
                    <li className='list-group-item' key={health.id}>
                      {health.name}
                      <div className='material-switch pull-right'>
                        <input
                          id={health.name}
                          name={health.name}
                          type='checkbox'
                          checked={health.checked}
                          onChange={(e) => {
                            handleChange(e);
                            handleUpdate(e);
                          }}
                        />
                        <label
                          htmlFor={health.name}
                          className='label-default'
                        />
                      </div>
                      {health.name === 'Otros' && health.checked && (
                        <div
                          className='form-group'
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          <label
                            style={{
                              marginRight: 10,
                            }}
                          >
                            Especifique:
                          </label>
                          <input
                            type='text'
                            value={other}
                            name='other'
                            onChange={handleChangeOther}
                            style={{
                              outline: 'none',
                            }}
                          />
                          <button
                            type='button'
                            style={{
                              margin: 5,
                              background: `${
                                other === '' ? '#CDCDCD' : '#1187E7'
                              }`,
                              color: '#ffff',
                              fontSize: 14,
                              border: 'none',
                              cursor: 'pointer',
                              outline: 'none',
                            }}
                            disabled={other === ''}
                            onClick={handleUpdateOther}
                          >
                            Guardar
                          </button>
                        </div>
                      )}
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  patientID: state.profile.searchPatientID,
  userHealth: state.history.health.items,
  defaultHealth: state.history.health.def,
  selectedHealth: state.history.health.selected,
  userRef: state.reference.userRef,
});

export default connect(mapStateToProps, {
  deleteHealth,
  addHealth,
  setSelectedHealth,
  setTrueDefHealth,
  updateHealth,
})(HealthHistory);
