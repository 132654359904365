import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { checkUserRestoreAvailable, saveRestoreUser } from '../../api';
import Swal from 'sweetalert2';

export const ValidUserByDni = ({ isOpen, handleClose }) => {
  const [isError, setIsError] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [mode, setmode] = useState('email');
  const [dni, setDni] = useState('');
  const [register, setRegister] = useState('');
  const [newRegister, setNewRegister] = useState('');

  const handleValidDni = async () => {
    if (isError) {
      setIsError(false);
    }

    try {
      const res = await checkUserRestoreAvailable({ dni, register });
      if (res.statusCode === 200) {
        if (res.type === 'email') {
          setmode('email');
        } else {
          setmode('cellphone');
        }
        setIsAvailable(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const handleChangeNewRegister = (e) => {
    setNewRegister(e.target.value);
    if (isError) {
      setIsError(false);
    }
  };

  const handleChange = (e) => {
    setDni(e.target.value);
    if (isError) {
      setIsError(false);
    }
  };

  const handleChangeClose = () => {
    setDni('');
    setIsError(false);
    setIsAvailable(false);
    setmode('email');
    setRegister('');
    setNewRegister('');
    handleClose();
  };

  const handleChangeRegister = (e) => {
    setRegister(e.target.value);
  };

  const handleSaveNewRegister = async () => {
    try {
      await saveRestoreUser({
        registerType: mode,
        value: newRegister,
        dni,
      });

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Has recuperado tu cuenta de forma exitosa',
        showConfirmButton: false,
        timer: 2000,
      });
      handleChangeClose();
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <Modal show={isOpen} centered>
      <Modal.Header closeButton onHide={handleChangeClose}>
        <Modal.Title>
          <h4>Recuperación de cuenta</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isAvailable ? (
          <>
            <h6>
              Puedes brindarnos tu número de identificación para validar tu
              cuenta.
            </h6>
            <Form.Control
              type='text'
              name='dni'
              value={dni}
              onChange={handleChange}
            />
            <h6>
              Cúal es el correo electrónico/teléfono que tenías registrado en tu
              cuenta
            </h6>
            <Form.Control
              type='text'
              name='register'
              value={register}
              onChange={handleChangeRegister}
            />

            {isError && (
              <span className='text-danger'>
                El número de identificación, el Correo/teléfono no es válido o
                no esta registrado
              </span>
            )}
          </>
        ) : (
          <>
            <h6>Ingresa tu nuevo correo electrónico/teléfono</h6>
            <Form.Control
              type='text'
              name='newRegister'
              value={newRegister}
              onChange={handleChangeNewRegister}
            />
            {isError && (
              <span className='text-danger'>
                El correo electrónico/téfono se encuentra registrado en otra
                cuenta
              </span>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isAvailable ? (
          <>
            <Button
              variant='primary'
              style={{
                borderRadius: 300,
              }}
              onClick={handleSaveNewRegister}
              disabled={newRegister.length < 8}
            >
              Guardar
            </Button>
          </>
        ) : (
          <>
            <Button
              variant='primary'
              style={{
                borderRadius: 300,
              }}
              onClick={handleValidDni}
              disabled={dni.length < 8 && register.length > 8}
            >
              Validar
            </Button>
          </>
        )}

        <Button
          variant='danger'
          onClick={handleChangeClose}
          style={{
            borderRadius: 300,
          }}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
