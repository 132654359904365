/* eslint-disable global-require */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { AiOutlineCloudUpload, BsFiles } from 'react-icons/all';
import createAlert from '../../redux/actions/alert.action';
import Presale from './Presale';
import AddDiagnosis from '../Modal/AddDiagnosis';
import AddReason from '../Modal/AddReason';
import ModalVitalSigns from '../Modal/ModalVitalSigns';
import ModalNursingNotes from '../Modal/ModalNursingNotes';
import ModalMedicalOrders from '../Modal/ModalMedicalOrders';
import AddHeaModal from '../Modal/addHEA';
import AddIndications from '../Modal/AddIndications';
import {
  addVerifyConsult,
  addVideoconference,
} from '../../redux/actions/videoconference.action';
import {
  endCallVideconference,
  getOneVideoconference,
  cancelVideoconference,
} from '../../api/videoconference';
import { addConsult } from '../../utils/isConsult';
import { changeConsultDoctorOffice } from '../../websokect/consultDoctorOfficeWebsocket';
import { addUpdatePresale } from '../../api/presale';
import AddProductPresale from './presale/addProductPresale';
import AddServicesPresale from './presale/addServicesPresale';
import ModalMedicalHistory from '../Modal/ModalMedicalHistory';
import AddPhysicalTestModal from '../Modal/AddPhysicalTestModal';
import ModalAddSchedule from '../../Components/Card/ModalAddconsulting/ModalAddConsulting';
import { openModalAddScheduling } from '../../redux/actions/ui.action';
import {
  getDoctorReference,
  getReference,
} from '../../redux/actions/references';
import { validProductKielsa } from '../../api/productKielsa';
import ModalLabHistory from '../Lab/ModalLabHistory';
import ModalUploadLabFile from '../Lab/ModalUploadLabFile';

const textGender = (gender) => (gender === 'male' ? 'Masculino' : 'Femenino');

const imageGender = (gender) =>
  gender === 'male'
    ? require('../../assets/img/male.png')
    : require('../../assets/img/female.png');

const FacetoFaceConsultationPatient = ({
  token,
  match,
  addVideoconference,
  exams,
  isOpenModalAddScheduling,
  doctorReference,
  getReference,
  openModalAddScheduling,
  videoconferenceRef,
}) => {
  const { params } = match;
  const { roomId } = params;
  const [, setSeconds] = useState(0);
  const [reason, setReason] = useState('');
  const [nurse, setNurse] = useState(undefined);
  const [hea, setHEA] = useState('');
  const [nursingNote, setNursingNote] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [indications, setIndications] = useState([]);
  const [historyPatient, setHistoryPatient] = useState(undefined);
  const [, setHeaHistory] = useState([]);
  const [doctor, setDoctor] = useState();
  const [patient, setPatient] = useState();
  const [, setLoading] = useState(true);
  const [, setLimitSeconds] = useState();
  const [, setScheduleVideo] = useState();
  const [consultDoctoroOffice, setConsultDoctorOffice] = useState();
  const [arrayProducts, setArrayProducts] = useState([]);
  const [arrayProductsDelete, setArrayProductsDelete] = useState([]);
  const [arrayServices, setArrayServices] = useState([]);
  const [modalLabHistory, setModalLabHistory] = useState(false);
  const [modalUploadLabFile, setModalUploadLabFile] = useState(false);
  const [signsVital, setSignsVital] = useState({
    temperature: '',
    pressure: {
      initial: '',
      final: '',
    },
    heartRate: '',
    breathingFrequency: '',
    saturation: '',
    weight: '',
    height: '',
    imc: '',
  });

  const dispatch = useDispatch();

  const [validVital, setValidVital] = useState(false);
  const {
    temperature,
    pressure,
    heartRate,
    breathingFrequency,
    saturation,
    weight,
    height,
    imc,
  } = signsVital;
  useEffect(() => {
    let interval;
    getOneVideoconference({ idVideo: roomId, token })
      .then((response) => {
        const {
          videoconference,
          historyPatient: dataHistory,
          heaHistory: dataHea,
          patient: dataPatient,
          doctor: dataDoctor,
          maxSecondPatient,
          dataSchedule,
          dataConsultDoctorOffice,
          dataPresale,
          nurse: dataNurse,
        } = response;

        // agregnado videconferencia a redux
        addVideoconference(videoconference);
        const { secondsDoctor } = videoconference;
        setScheduleVideo(dataSchedule);
        setHistoryPatient(dataHistory);
        setHeaHistory(dataHea);
        setPatient(dataPatient);
        setDoctor(dataDoctor);
        setLimitSeconds(maxSecondPatient);
        setSeconds(secondsDoctor);
        setNurse(dataNurse);
        setConsultDoctorOffice(dataConsultDoctorOffice);

        if (videoconference.vitalSings) {
          setSignsVital(videoconference.vitalSings);
        }

        if (dataPresale) {
          setArrayProducts(dataPresale.arrayProducts);
          setArrayServices(dataPresale.arrayServices);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        createAlert(
          'Error!!',
          'Error al ingresar a la videconferencia',
          'error'
        );
        window.location.href = '/dashboard';
      });
  }, []);

  const handleSubmitReason = (text) => {
    setReason(text);
    $('#Modalmotivo').modal('hide');
  };

  const handleSubmitIndications = (indication) => {
    indications.push(indication);
    const newArray = indications.map((item) => item);
    setIndications(newArray);
  };

  const handleRemoveIndications = (index) => {
    indications.splice(index, 1);
    const newArray = indications.map((item) => item);
    setIndications(newArray);
  };

  const handleSubmitDiagnosis = (text) => {
    setDiagnosis(text);
    $('#Modaldiagnostico').modal('hide');
  };

  const handleSubmitHea = (text) => {
    setHEA(text);
    $('#ModalHeaForm').modal('hide');
  };

  const handleUpdateData = () => {
    getOneVideoconference({ token: token, idVideo: roomId }).then((res) => {
      const { historyPatient } = res;
      setHistoryPatient(historyPatient);
    });
  };

  const handleSubmitSignsVital = (e) => {
    setSignsVital({
      temperature: e.target.temperatura.value,
      pressure: {
        initial: e.target.presion_initial.value,
        final: e.target.presion_final.value,
      },
      breathingFrequency: e.target.frecuencia_resp.value,
      heartRate: e.target.frecuencia_card.value,
      saturation: e.target.saturacion.value,
      weight: e.target.peso.value,
      height: e.target.estatura.value,
      imc: e.target.IMC.value,
    });
    setValidVital(true);
    $('#ModalVitalSigns').modal('hide');
  };

  const handleSubmitnursingNote = (text) => {
    setNursingNote(text);
    $('#ModalNursingNotes').modal('hide');
  };

  const handleSendSchedule = (schedule) => {
    setScheduleVideo(schedule);
  };

  const handleEndConsultPresencial = () => {
    if (!reason && videoconferenceRef?.type !== 'lab') {
      createAlert(
        '¡Error!',
        'No ha ingresado ningún motivo de la consulta',
        'error'
      );
      return;
    } else if (!diagnosis && videoconferenceRef?.type !== 'lab') {
      createAlert(
        '¡Error!',
        'No ha ingresado ningún diagnóstico de la consulta',
        'error'
      );
      return;
    } else if (!hea && videoconferenceRef?.type !== 'lab') {
      createAlert(
        '¡Error!',
        'No ha ingresado ningún HEA de la consulta',
        'error'
      );
      return;
    }
    Swal.fire({
      icon: 'info',
      title: '¿Estás seguro desea salir de la consulta?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((res) => {
      const { isConfirmed } = res;
      if (isConfirmed) {
        endCallVideconference({
          token: token,
          endCallDate: new Date(),
          idVideoconference: roomId,
          diagnosis: diagnosis,
          indications: indications,
          reason: reason,
          hea: hea,
          nursingNote,
          vitalSings: signsVital,
          physicalTest: exams,
        })
          .then(() => {
            changeConsultDoctorOffice({ idConsult: consultDoctoroOffice._id });
            addConsult();
            window.location.href = '/dashboard';
          })
          .catch((error) => {
            console.log(error);
            createAlert(
              '¡Error!',
              'Ocurrió un error al finalizar la videconferencia.',
              'error'
            );
          });
      }
    });
  };

  const handleCancelVideoconference = () => {
    Swal.fire({
      title: 'Escriba el motivo de la cancelacion',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Si',
      showLoaderOnConfirm: true,
      cancelButtonText: 'No',
      preConfirm: (reasonCancel) =>
        cancelVideoconference({
          token: token,
          reasonCancel: reasonCancel,
          _id: roomId,
        })
          .then((res) => res)
          .catch((error) => {
            if (error.info) {
              Swal.showValidationMessage(error.info);
            } else {
              Swal.showValidationMessage(
                'Error al cancelar la consulta presencial'
              );
            }
          }),
    }).then((result) => {
      if (result.isConfirmed) {
        changeConsultDoctorOffice({ idConsult: consultDoctoroOffice._id });
        addConsult();
        window.location.href = '/dashboard';
      }
    });
  };

  const handleCloseModalLabHistory = () => {
    setModalLabHistory(false);
  };

  const handleCloseModalUploadLabFile = () => {
    setModalUploadLabFile(false);
  };

  const handleOpenModalLabHistory = () => {
    setModalLabHistory(true);
  };

  const handleCloseModalUploadFile = () => {
    setModalUploadLabFile(true);
  };

  return (
    <div className='container-fluid'>
      {modalLabHistory && (
        <ModalLabHistory
          show={modalLabHistory}
          handleClose={handleCloseModalLabHistory}
          token={token}
        />
      )}

      {modalUploadLabFile && (
        <ModalUploadLabFile
          show={modalUploadLabFile}
          token={token}
          handleClose={handleCloseModalUploadLabFile}
        />
      )}
      <div className='user-dashboard'>
        <h2 className='text-primary mb-5 mt-5'>
          {videoconferenceRef?.type !== 'lab' && 'Cita'}
          &nbsp;
          <b className='text-secondarymdv'>
            {videoconferenceRef?.type === 'presencial' && 'Presencial'}
            {videoconferenceRef?.type === 'videocall' && 'Videollamada'}
            {videoconferenceRef?.type === 'follow-up-presential' &&
              'de seguimineto Presencial'}
            {videoconferenceRef?.type === 'follow-up-presential-whatsapp' &&
              'de seguimineto presencial mediante WhatsApp'}
            {videoconferenceRef?.type === 'follow-up-videocall' &&
              'de seguimineto mediante videollamada'}
            {videoconferenceRef?.type === 'follow-up-call' &&
              'de seguimineto mediante Llamada telefónica'}
            {videoconferenceRef?.type === 'call' && 'llamada telefónica'}
            {videoconferenceRef?.type === 'whatsapp' && 'mediante Whatsapp'}
            {videoconferenceRef?.type === 'lab' && 'Servicio laboratorio'}
          </b>
          {/* <span className="badge bg-white float-right text-black-50">Tiempo transcurrido: 3:00 Min</span> */}
        </h2>
        {/* modales */}
        <AddReason handleReason={handleSubmitReason} reason={reason} />
        {signsVital && (
          <ModalVitalSigns
            hadleSignsVital={handleSubmitSignsVital}
            temperatura={temperature}
            presion={pressure}
            frecuencia_resp={breathingFrequency}
            frecuencia_card={heartRate}
            saturacion={saturation}
            peso={weight}
            estatura={height}
            IMC={imc}
          />
        )}
        <ModalMedicalHistory
          updateData={handleUpdateData}
          historyPatient={historyPatient}
          patientId={patient?.publicData._id}
          idVideo={roomId}
        />
        <AddIndications
          indications={indications}
          handleSubmitIndication={handleSubmitIndications}
          handleRemoveIndications={handleRemoveIndications}
        />
        <ModalNursingNotes
          nursingNote={nursingNote}
          handleNursingNote={handleSubmitnursingNote}
        />
        <AddDiagnosis
          handleDiagnosis={handleSubmitDiagnosis}
          diagnosis={diagnosis}
        />
        <AddPhysicalTestModal />
        <ModalMedicalOrders />
        <AddHeaModal hea={hea} handleHea={handleSubmitHea} />
        {isOpenModalAddScheduling && doctorReference !== null && (
          <ModalAddSchedule />
        )}
        {/* <AddSchedule
          scheduleVideo={scheduleVideo}
          idVideo={roomId}
          sendSchedule={handleSendSchedule}
          token={token}
          patient={patient?.publicData._id}
        /> */}
        {/* fin modales */}
        <div className='row'>
          <div className='col-12 col-md-7'>
            {/* aqui debe de ir */}
            <div className='row row-cols-2 row-cols-sm-2 row-cols-md-4 justify-content-center'>
              {videoconferenceRef?.type !== 'lab' && (
                <>
                  <div className='col p-1'>
                    <button
                      className='btn btn-link btn-block border-0 text-secondarymdv bg-white shadow-sm h-100'
                      style={{ borderRadius: '0.8rem' }}
                      type='button'
                      data-toggle='modal'
                      data-target='#Modalmotivo'
                      data-whatever='@getbootstrap'
                    >
                      {reason && (
                        <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                      )}
                      <img
                        src={require('../../assets/img/icons/motivo-consulta.png')}
                        alt='logo'
                        className='img-fluid'
                      />
                    </button>
                  </div>
                  <div className='col p-1'>
                    <button
                      className='btn btn-link btn-block border-0 text-secondarymdv bg-white shadow-sm h-100'
                      style={{ borderRadius: '0.8rem' }}
                      type='button'
                      data-toggle='modal'
                      data-target='#ModalHeaForm'
                      data-whatever='@getbootstrap'
                    >
                      {hea && (
                        <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                      )}
                      <img
                        src={require('../../assets/img/icons/HEA.png')}
                        alt='logo'
                        className='img-fluid'
                      />
                    </button>
                  </div>
                </>
              )}
              {videoconferenceRef?.type !== 'call' &&
                videoconferenceRef?.type !== 'whatsapp' &&
                videoconferenceRef?.type !== 'lab' && (
                  <>
                    <div className='col p-1'>
                      <button
                        className='btn btn-link border-0 text-secondarymdv bg-white shadow-sm btn-block h-100'
                        style={{
                          borderRadius: '0.8rem',
                        }}
                        type='button'
                        data-toggle='modal'
                        data-target='#ModalVitalSigns'
                        data-whatever='@getbootstrap'
                      >
                        {validVital && (
                          <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                        )}
                        <img
                          src={require('../../assets/img/icons/signos-vitales.png')}
                          alt='logo'
                          className='img-fluid'
                        />
                      </button>
                    </div>
                    <div className='col p-1'>
                      <button
                        className='btn btn-link border-0 text-secondarymdv bg-white shadow-sm btn-block h-100'
                        style={{ borderRadius: '0.8rem' }}
                        type='button'
                        data-toggle='modal'
                        data-target='#ModalPhysicalTest'
                        data-whatever='@getbootstrap'
                      >
                        {exams?.find(
                          ({ description }) => description !== null
                        ) && (
                          <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                        )}
                        <img
                          src={require('../../assets/img/icons/examen-fisico.png')}
                          alt='logo'
                          className='img-fluid'
                        />
                      </button>
                    </div>
                  </>
                )}
              <div className='col p-1'>
                <button
                  className='btn btn-link border-0 text-secondarymdv bg-white shadow-sm btn-block h-100'
                  style={{ borderRadius: '0.8rem' }}
                  type='button'
                  data-toggle='modal'
                  data-target='#Modaldiagnostico'
                  data-whatever='@getbootstrap'
                >
                  {diagnosis && (
                    <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                  )}
                  <img
                    src={require('../../assets/img/icons/Diagnóstico.png')}
                    alt='logo'
                    className='img-fluid'
                  />
                </button>
              </div>

              {videoconferenceRef?.type !== 'lab' && (
                <div className='col p-1'>
                  <button
                    className='btn btn-link border-0 text-secondarymdv bg-white shadow-sm btn-block h-100'
                    style={{ borderRadius: '0.8rem' }}
                    data-toggle='modal'
                    type='button'
                    data-target='#Modalindicaciones'
                    data-whatever='@getbootstrap'
                  >
                    {indications.length > 0 && (
                      <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/Indicación.png')}
                      alt='logo'
                      className='img-fluid'
                    />
                  </button>
                </div>
              )}
              {/* <div className="col p-1">
                <button
                  className="btn btn-link border-0 text-secondarymdv bg-white shadow-sm btn-block h-100"
                  style={{borderRadius: '0.8rem'}}
                  type="button"
                  data-toggle="modal"
                  data-target="#ModalMedicalOrders"
                  data-whatever="@getbootstrap"
                >
                  <img
                    src={require('../../assets/img/icons/ordenes-medicas.png')}
                    alt="logo"
                    className="img-fluid"
                  />
                </button>
              </div> */}
              {/* <div className="col p-1">
                <button
                  className="btn btn-link btn-block border-0 text-secondarymdv bg-white shadow-sm h-100"
                  style={{borderRadius: '0.8rem'}}
                  type="button"
                  data-toggle="modal"
                  data-target="#ModalNursingNotes"
                  data-whatever="@getbootstrap"
                >
                  {nursingNote && (
                    <i className="fas fa-check-square fa-2x check-doctor text-success mr-2" />
                  )}
                  <img
                    src={require('../../assets/img/icons/notas-enfermeria.png')}
                    alt="logo"
                    className="img-fluid"
                  />
                </button>
              </div> */}
              <div className='col p-1'>
                <button
                  className='btn btn-link btn-block border-0 text-secondarymdv bg-white shadow-sm h-100'
                  style={{ borderRadius: '0.8rem' }}
                  type='button'
                  data-whatever='@getbootstrap'
                  onClick={() => {
                    openModalAddScheduling();
                    dispatch(getDoctorReference(doctor.publicData._id));
                    getReference(videoconferenceRef?.patient);
                  }}
                >
                  <img
                    src={require('../../assets/img/icons/agendar-cita.png')}
                    alt='logo'
                    className='img-fluid'
                  />
                </button>
              </div>
              {videoconferenceRef?.type !== 'lab' && (
                <div className='col p-1'>
                  <button
                    type='button'
                    className='btn btn-link btn-block border-0 text-secondarymdv bg-white shadow-sm h-100'
                    style={{ borderRadius: '0.8rem' }}
                    data-toggle='modal'
                    data-target='#ModalMedicalPatient'
                    onClick={() => {
                      getReference(videoconferenceRef?.patient);
                    }}
                  >
                    <img
                      src={require('../../assets/img/icons/Historial.png')}
                      alt='logo'
                      className='img-fluid'
                    />
                  </button>
                </div>
              )}

              {videoconferenceRef?.type === 'lab' && (
                <>
                  <div className='col p-1'>
                    <button
                      className='btn border-0 text bg-white shadow-sm btn-block h-100'
                      style={{
                        borderRadius: '0.8rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        fontWeight: 'lighter',
                        color: '#0090D5',
                      }}
                      type='button'
                      onClick={handleCloseModalUploadFile}
                    >
                      <AiOutlineCloudUpload size={50} />
                      <span
                        style={{
                          color: '#2A5492',
                        }}
                      >
                        Cargar exámen
                      </span>
                    </button>
                  </div>
                  <div className='col p-1'>
                    <button
                      className='btn border-0 bg-white shadow-sm btn-block h-100'
                      style={{
                        borderRadius: '0.8rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        fontWeight: 'lighter',
                        color: '#0090D5',
                      }}
                      type='button'
                      onClick={handleOpenModalLabHistory}
                    >
                      <BsFiles size={50} />
                      <span
                        style={{
                          color: '#2A5492',
                        }}
                      >
                        Historial Exámenes
                      </span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='col-12 col-md-5'>
            <div className='sales report mb-4 text-center'>
              <div className='profile-header'>
                <h3 className='text-white text-center pt-4'>
                  Perfil de Paciente
                </h3>
              </div>
              <h4 className='text-primary font-weight-bold mt-3'>
                {`${patient?.publicData.firstName} ${patient?.publicData.lastName}`}
              </h4>
              <img
                src={
                  patient?.publicData.image
                    ? `/api/profile/image/${patient?.publicData.image}`
                    : imageGender(patient?.publicData.gender)
                }
                alt='foto-paciente'
                width='110'
                className='img-fluid mt-2'
              />
              <div className='container mt-3'>
                <div className='hr' />
                <div className='row no-gutters justify-content-center'>
                  <div className='col-12 col-md-4 col-sm-12 border-right'>
                    <p>
                      <i className='far fa-id-badge mr-1' />
                      {patient?.publicData.dni}
                    </p>
                  </div>
                  <div className='col-12 col-md-4 col-sm-12 border-right'>
                    <p>
                      <i className='fas fa-venus-mars mr-1' />
                      {textGender(patient?.publicData.gender)}
                    </p>
                  </div>
                  <div className='col-12 col-md-4 col-sm-12'>
                    <p>
                      <i className='fas fa-phone-alt mr-1' />
                      {patient?.profileData.cellphone
                        ? patient?.profileData.cellphone
                        : 'No posee'}
                    </p>
                  </div>
                  <div className='col-12 col-md-4 col-sm-12'>
                    <p>
                      <i className='fas fa-envelope-open-text mr-1' />
                      {patient?.publicData.email}
                    </p>
                    <p>
                      {`Edad: ${
                        patient?.profileData.bornAt
                          ? moment(Date.now()).diff(
                              moment(patient?.profileData.bornAt),
                              'years'
                            )
                          : 'No especificada'
                      } años`}
                    </p>
                  </div>
                </div>
                {/* Boton de finalizar consulta */}
                <button
                  type='button'
                  className='btn btn-light btn-block border bg-danger text-white'
                  style={{ borderRadius: '0.8rem', height: '77px' }}
                  onClick={handleEndConsultPresencial}
                >
                  <h5 className='text-white mt-2'>
                    <i className='fas fa-sign-out-alt mr-2' />
                    {`Finalizar ${
                      videoconferenceRef?.type ===
                        'follow-up-presential-whatsapp' ||
                      videoconferenceRef?.type === 'follow-up-call' ||
                      videoconferenceRef?.type === 'follow-up-presential'
                        ? 'Seguimiento'
                        : 'Consulta'
                    }`}
                  </h5>
                </button>
                <button
                  type='button'
                  className='btn btn-light btn-block border bg-secondary text-white'
                  style={{ borderRadius: '0.8rem', height: '77px' }}
                  onClick={handleCancelVideoconference}
                >
                  <h5 className='text-white mt-2'>
                    <i className='fas fa-power-off mr-2' />
                    {`Cancelar ${
                      videoconferenceRef?.type ===
                        'follow-up-presential-whatsapp' ||
                      videoconferenceRef?.type === 'follow-up-call' ||
                      videoconferenceRef?.type === 'follow-up-presential'
                        ? 'Seguimiento'
                        : 'Consulta'
                    }`}
                  </h5>
                </button>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <Presale
              products={arrayProducts}
              services={arrayServices}
              nurse={nurse}
              patient={patient}
              doctor={doctor}
              hour={consultDoctoroOffice?.hour}
              removeLastProduct={() => {
                if (arrayProducts.length > 0) {
                  const index = arrayProducts.length - 1;

                  // Crear una copia del último producto con action: 'DELETE'
                  const productDeleted = {
                    ...arrayProducts[index],
                    action: 'DELETE',
                  };

                  // Agregar el producto eliminado a arrayProductsDelete
                  setArrayProductsDelete([
                    ...arrayProductsDelete,
                    productDeleted,
                  ]);

                  // Crear un nuevo array sin el último producto
                  const newArray = arrayProducts.slice(0, index);

                  // Actualizar arrayProducts con el nuevo array
                  setArrayProducts(newArray);

                  console.log(
                    'Array de productos borrados:',
                    arrayProductsDelete
                  );
                }
              }}
              removeLastService={() => {
                if (arrayServices.length > 0) {
                  const index = arrayServices.length - 1;
                  arrayServices.splice(index, 1);
                  const newArray = arrayProducts.map((item) => item);
                  setArrayServices(newArray);
                }
              }}
              registerPresale={() => {
                addUpdatePresale({
                  token: token,
                  idVideo: roomId,
                  arrayProducts: arrayProducts,
                  arrayServices: arrayServices,
                  arrayProductsDelete: arrayProductsDelete,
                })
                  .then(() => {
                    if (arrayProducts.length > 0 || arrayServices.length > 0) {
                      Swal.fire({
                        icon: 'success',
                        title: 'Se actualizó la preventa de manera exitosa.',
                      });
                    } else {
                      Swal.fire({
                        icon: 'success',
                        title: 'Se agregó la preventa de manera exitosa.',
                      });
                    }
                  })
                  .catch((error) => {
                    if (error.info) {
                      Swal.fire({
                        icon: 'error',
                        title: error.info,
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos no se pudo crear la preventa.',
                      });
                    }
                  });
              }}
            />
          </div>
          <div className='col-12 col-md-6'>
            <AddProductPresale
              addProduct={(doc) => {
                validProductKielsa({
                  token: token,
                  articuloId: doc.code,
                  quantity: doc.amount,
                  idVideoconference: roomId,
                })
                  .then((res) => {
                    if (res.valid) {
                      arrayProducts.push(doc);
                      const newArray = arrayProducts.map((item) => item);
                      setArrayProducts(newArray);
                    } else if (res.noExists) {
                      Swal.fire({
                        icon: 'error',
                        text: `El producto con el codigo ${doc.code} no existe en la farmacia`,
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: `El producto con el codigo ${doc.code} solo posee ${res.existence} en existencia`,
                      });
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      icon: 'error',
                      text: 'Por favor contacte con servicio tecnico',
                    });
                  });
              }}
              showDetail={(doc) => {
                validProductKielsa({
                  token: token,
                  articuloId: doc.code,
                  quantity: doc.amount,
                  idVideoconference: roomId,
                })
                  .then((res) => {
                    if (res.noExists) {
                      Swal.fire({
                        icon: 'info',
                        text: `El producto con el codigo ${doc.code} no existe en la farmacia`,
                      });
                    } else {
                      Swal.fire({
                        icon: 'info',
                        text: `El producto con el codigo ${doc.code} posee ${res.existence} en existencia}`,
                      });
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      icon: 'error',
                      text: 'Por favor contacte con servicio tecnico',
                    });
                  });
              }}
              products={arrayProducts}
              token={token}
            />
            <AddServicesPresale
              services={arrayServices}
              addService={(doc) => {
                arrayServices.push(doc);
                const newArray = arrayServices.map((doc) => doc);
                setArrayServices(newArray);
              }}
              token={token}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  ...state.auth,
  ...state.physicalTest,
  isOpenModalAddScheduling: state.ui.schedulingModal.open,
  doctorReference: state.reference.doctor,
  patientReference: state.reference.userRef,
  videoconferenceRef: state.videoconference.videoconference,
});

export default connect(mapStatetoProps, {
  addVerifyConsult,
  addVideoconference,
  openModalAddScheduling,
  getReference,
})(FacetoFaceConsultationPatient);
