/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  deletevaccinate,
  addvaccinate,
  updateVaccinate,
  setSelectedVaccinate,
} from '../../../redux/actions/vaccinate.action';
import { setTrueDefVaccinate } from '../../../redux/actions/patient.history.action';

function Vaccinate({
  userVaccinates,
  deletevaccinate,
  addvaccinate,
  userRef,
  selectedVaccinate,
  updateVaccinate,
  setSelectedVaccinate,
  defaultVaccinates,
  setTrueDefVaccinate,
}) {
  const [other, setOtherValue] = useState('');

  // useEffect para sincronizar las vacunas del usuario con las vacunas predeterminadas
  useEffect(() => {
    // Clona el array de defaultVaccinates para evitar mutaciones directas
    const updatedDefaultVaccinates = defaultVaccinates.map((def) => {
      const isUserVaccinate = userVaccinates.some(
        (actual) => actual.name === def.name
      );
      return { ...def, checked: isUserVaccinate };
    });

    // Actualiza los estados globales con Redux
    updatedDefaultVaccinates.forEach((item) => {
      setTrueDefVaccinate(item);
      if (item.name === 'Otros' && item.checked) {
        const otherVaccinate = userVaccinates.find(
          (actual) => actual.name === item.name
        );
        setSelectedVaccinate(otherVaccinate);
      }
    });
  }, [
    userVaccinates,
    defaultVaccinates,
    setTrueDefVaccinate,
    setSelectedVaccinate,
  ]);

  // Maneja los cambios en los checkboxes
  const handleChange = (e) => {
    const tempSelectedItem = defaultVaccinates.find(
      (item) => item.name === e.target.name
    );

    if (tempSelectedItem) {
      const updatedItem = {
        ...tempSelectedItem,
        checked: !tempSelectedItem.checked,
      };
      setTrueDefVaccinate(updatedItem);
      setSelectedVaccinate(updatedItem);
    }
  };

  // Maneja la actualización del estado en la base de datos
  const handleUpdate = (e) => {
    const tempSelectedItem = defaultVaccinates.find(
      (item) => item.name === e.target.name
    );

    if (tempSelectedItem) {
      if (tempSelectedItem.checked === true) {
        addvaccinate(e.target.name, userRef?._id);
      } else {
        userVaccinates.forEach((element) => {
          if (element.name === e.target.name) {
            if (e.target.name === 'Otros') {
              setSelectedVaccinate(null);
            }
            deletevaccinate(element._id);
          }
        });
      }
    }
  };

  // Maneja los cambios en el campo "Otros"
  const handleChangeOther = (e) => {
    setOtherValue(e.target.value);
  };

  // Actualiza el valor de "Otros" en la base de datos
  const handleUpdateOther = (e) => {
    e.preventDefault();
    updateVaccinate(selectedVaccinate._id, other);
    setOtherValue('');
  };

  return (
    <div>
      <div className='sales mt-3 pr-0 pl-0'>
        <div className='media'>
          <div className='media-body'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <h5 className='font-blue mt-2 mb-3 text-center'>
                  Agregar tipo de Vacunas
                </h5>
              </div>

              <ul className='list-group list-group-flush'>
                {defaultVaccinates.map((vacuna) => (
                  <li className='list-group-item' key={vacuna.id}>
                    {vacuna.name}
                    <div className='material-switch pull-right'>
                      <input
                        id={vacuna.name}
                        name={vacuna.name}
                        type='checkbox'
                        checked={vacuna.checked || false}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate(e);
                        }}
                      />
                      <label htmlFor={vacuna.name} className='label-default' />
                    </div>
                    {vacuna.name === 'Otros' && vacuna.checked && (
                      <div
                        className='form-group'
                        style={{
                          paddingRight: 20,
                        }}
                      >
                        <label
                          style={{
                            marginRight: 10,
                          }}
                        >
                          Especifique:
                        </label>
                        <input
                          type='text'
                          value={other}
                          name='other'
                          onChange={handleChangeOther}
                          style={{
                            outline: 'none',
                          }}
                        />
                        <button
                          type='button'
                          style={{
                            margin: 5,
                            background: `${
                              other === '' ? '#CDCDCD' : '#1187E7'
                            }`,
                            color: '#ffff',
                            fontSize: 14,
                            border: 'none',
                            cursor: 'pointer',
                            outline: 'none',
                          }}
                          disabled={other === ''}
                          onClick={handleUpdateOther}
                        >
                          Guardar
                        </button>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userVaccinates: state.history.vaccinate.items,
  userRef: state.reference.userRef,
  selectedVaccinate: state.history.vaccinate.selected,
  defaultVaccinates: state.history.vaccinate.def,
});

export default connect(mapStateToProps, {
  deletevaccinate,
  addvaccinate,
  updateVaccinate,
  setSelectedVaccinate,
  setTrueDefVaccinate,
})(Vaccinate);
