/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import Avatar from 'react-avatar';
import moment from 'moment';
import '../../stylesheets/presential.css';
import { validUserPatientId } from '../../api/user';

const ActivePlan = ({ patientId }) => {
  const [activePlan, setActivePlan] = useState(null);
  const [details, setDetails] = useState(null);
  useEffect(() => {
    validUserPatientId({ patientId })
      .then((res) => {
        setActivePlan(true);
        setDetails(res.plan);
      })
      .catch(() => {
        setActivePlan(false);
      });
  }, [patientId]);
  if (activePlan === null) {
    return <></>;
  }
  if (activePlan) {
    return (
      <div className='mt-4 d-flex flex-column align-items-center'>
        <h6 className='text-primary'>Detalles del Plan</h6>
        <h6 className='profile-secondary-text'>
          <label style={{ fontWeight: 500 }}>Nombre del plan</label>
          {` / ${details?.plan?.name}`}
        </h6>
        <h6 className='profile-secondary-text'>
          <label style={{ fontWeight: 500 }}>Fecha de vencimiento</label>
          {` / ${moment.utc(details?.dueDate).format('LLLL')}`}
        </h6>
      </div>
    );
  } else {
    return <>No cuenta con plan activo</>;
  }
};

const PresentialUserDetails = ({ userRef, loading }) => {
  const generateIdType = () => {
    if (userRef?.idType === 'national') {
      return 'ID nacional';
    } else if (userRef?.idType === 'foreign') {
      return 'ID extranjero';
    } else {
      return 'RTN';
    }
  };

  const generateUserRole = () => {
    if (userRef?.role === 'doctor') {
      return 'Doctor';
    } else if (userRef?.role === 'nurse') {
      return 'Enfermera';
    } else {
      return 'Paciente';
    }
  };

  return (
    <div>
      <div className='profile-header'>
        <h3 className='text-white text-center pt-4'>Perfil de usuario</h3>
      </div>
      <Row style={{ paddingTop: 15 }}>
        <Container>
          <div
            className='bg-white'
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Avatar size='110' round />
            <h4 className='pt-3 profile-text'>
              {`${userRef?.firstName?.split(' ', 1)} ${userRef?.lastName?.split(
                ' ',
                1
              )}`}
            </h4>
            {userRef?.email && (
              <h6 className='profile-secondary-text'>
                <label style={{ fontWeight: 500 }}>Correo</label>
                {` / ${userRef?.email}`}
              </h6>
            )}

            {userRef?.dni && (
              <h6 className='profile-secondary-text'>
                <label style={{ fontWeight: 500 }}>{generateIdType()}</label>
                {` / ${userRef?.dni}`}
              </h6>
            )}
            <h6 className='profile-secondary-text'>
              <label style={{ fontWeight: 500 }}>Género</label>
              {` / ${userRef?.gender === 'male' ? 'masculino' : 'femenino'}`}
            </h6>
            <h6 className='profile-secondary-text'>
              <label style={{ fontWeight: 500 }}>Tipo de usuario</label>
              {` / ${generateUserRole()}`}
            </h6>
            {loading ? (
              <p className='mt-2 text-center'>Cargando ...</p>
            ) : (
              <>
                {userRef?.role === 'patient' && (
                  <ActivePlan patientId={userRef?._id} />
                )}
              </>
            )}
          </div>
        </Container>
      </Row>
    </div>
  );
};

export default PresentialUserDetails;
