import axios from 'axios';

const uri = '/api/utils';

export const checkUserRestoreAvailable = async ({ dni, register }) => {
  try {
    const res = await axios.post(`${uri}/check-user-restore-account`, {
      dni,
      register,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const saveRestoreUser = async ({ registerType, value, dni }) => {
  try {
    const res = await axios.post(`${uri}/save-restore-user`, {
      dni,
      registerType,
      value,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
