/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  deleteFamiliarAntecedents,
  addFamiliarAntecedents,
  setSelectedFamiliarAntecedents,
  setTrueDefFamiliar,
  openModalFamiliar,
  closeModalFamiliar,
  updateFamiliarAntecedent,
} from '../../../redux/actions/patient.history.action';
import Modal from './modalFamiliarAntecedents';

function FamiliarHistory({
  deleteFamiliarAntecedents,
  selectedItemFamiliar,
  addFamiliarAntecedents,
  patientID,
  setSelectedFamiliarAntecedents,
  updateFamiliarAntecedent,
  userFamiliarAntecedents,
  defaultFamiliarAntecedents,
  setTrueDefFamiliar,
  openModalFamiliar,
  userRef,
}) {
  const [other, setOtherValue] = useState('');

  useEffect(() => {
    const updatedFamiliarAntecedents = defaultFamiliarAntecedents.map((def) => {
      const actual = userFamiliarAntecedents.find(
        (item) => item.name === def.name
      );

      // No modificar directamente, crea un nuevo objeto
      if (actual) {
        return {
          ...def,
          checked: true,
        };
      }
      return def;
    });

    // Solo actualiza si hay cambios en el array
    if (
      JSON.stringify(updatedFamiliarAntecedents) !==
      JSON.stringify(defaultFamiliarAntecedents)
    ) {
      updatedFamiliarAntecedents.forEach((item) => {
        if (item.checked) {
          setTrueDefFamiliar(item);
        }
        if (item.name === 'Otros' && item.checked) {
          const actual = userFamiliarAntecedents.find(
            (u) => u.name === 'Otros'
          );
          if (actual) setSelectedFamiliarAntecedents(actual);
        }
      });
    }
  }, [
    userFamiliarAntecedents,
    defaultFamiliarAntecedents,
    setTrueDefFamiliar,
    setSelectedFamiliarAntecedents,
  ]);

  const handleChange = (e) => {
    const tempSelectedItem = defaultFamiliarAntecedents.find(
      (item) => item.name === e.target.name
    );

    if (tempSelectedItem) {
      if (tempSelectedItem.checked) {
        tempSelectedItem.checked = !tempSelectedItem.checked;
        setTrueDefFamiliar(tempSelectedItem);
      } else {
        tempSelectedItem.checked = !tempSelectedItem.checked;
        setTrueDefFamiliar(tempSelectedItem);
        setSelectedFamiliarAntecedents(tempSelectedItem);
        openModalFamiliar();
      }
    }
  };

  const handleUpdate = (e) => {
    // encontrar el indice del state que decidira si se elimina o no un elemento de la base de datos
    const tempSelectedItem = defaultFamiliarAntecedents.find(
      (item) => item.name === e.target.name
    );

    if (tempSelectedItem) {
      if (tempSelectedItem.checked === true) {
        if (userRef) {
          addFamiliarAntecedents(e.target.name, userRef);
        } else {
          addFamiliarAntecedents(e.target.name, patientID);
        }
      } else {
        userFamiliarAntecedents.forEach((element) => {
          if (element.name === e.target.name) {
            if (e.target.name === 'Otros') {
              setSelectedFamiliarAntecedents(null);
            }
            deleteFamiliarAntecedents(element._id);
          }
        });
      }
    }
  };

  const handleChangeOther = (e) => {
    setOtherValue(e.target.value);
  };

  const handleUpdateOtherFamiliarAntecedents = () => {
    const tempSelectedItem = userFamiliarAntecedents.find(
      (item) => item.name === selectedItemFamiliar.name
    );

    if (tempSelectedItem) {
      tempSelectedItem.other.push(other);
      const formData = {
        other: tempSelectedItem.other,
      };

      updateFamiliarAntecedent(tempSelectedItem._id, formData);
    }
    setOtherValue('');
  };

  return (
    <div>
      {selectedItemFamiliar && <Modal />}
      <div className='sales mt-3 pr-0 pl-0'>
        <div className='media'>
          <div className='media-body'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <h5 className='font-blue mt-2 mb-3 text-center'>
                  Agregar Antecedentes Familiares
                </h5>
              </div>

              <ul className='list-group list-group-flush'>
                {defaultFamiliarAntecedents.map((antecedente) => (
                  <li className='list-group-item' key={antecedente.id}>
                    {antecedente.name}
                    <div className='material-switch pull-right'>
                      <input
                        id={antecedente.name}
                        name={antecedente.name}
                        type='checkbox'
                        checked={antecedente.checked}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate(e);
                        }}
                      />
                      <label
                        htmlFor={antecedente.name}
                        className='label-default'
                      />
                    </div>
                    {antecedente.name === 'Otros' && antecedente.checked && (
                      <div
                        className='form-group'
                        style={{
                          paddingRight: 20,
                        }}
                      >
                        <label
                          style={{
                            marginRight: 10,
                          }}
                        >
                          Especifique:
                        </label>
                        <input
                          type='text'
                          value={other}
                          name='other'
                          onChange={handleChangeOther}
                          style={{
                            outline: 'none',
                          }}
                        />
                        <button
                          type='button'
                          style={{
                            margin: 5,
                            background: `${
                              other === '' ? '#CDCDCD' : '#1187E7'
                            }`,
                            color: '#ffff',
                            fontSize: 14,
                            border: 'none',
                            cursor: 'pointer',
                            outline: 'none',
                          }}
                          disabled={other === ''}
                          onClick={handleUpdateOtherFamiliarAntecedents}
                        >
                          Guardar
                        </button>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userFamiliarAntecedents: state.history.familiar.items,
  selectedItemFamiliar: state.history.familiar.selectedItem,
  patientID: state.profile.searchPatientID,
  defaultFamiliarAntecedents: state.history.familiar.def,
  userRef: state.reference.userRef,
});

export default connect(mapStateToProps, {
  deleteFamiliarAntecedents,
  addFamiliarAntecedents,
  setSelectedFamiliarAntecedents,
  updateFamiliarAntecedent,
  setTrueDefFamiliar,
  openModalFamiliar,
  closeModalFamiliar,
})(FamiliarHistory);
