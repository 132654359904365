/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import 'moment/locale/es';
import { TiDelete, IoIosCheckmarkCircle } from 'react-icons/all';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import {
  getPresentialUserReference,
  setFalsePresentialAddUser,
  setFalsePresentialPhysicalExam,
  setFalsePresentialVitalSigns,
  setFalsePresentialProfile,
  resetPresentialState,
  setFalsePresentialComplete,
  deletePresentialPhysical,
  deletePresentialNurseDetails,
  setFalsePresentialNurseDetails,
  deletePresentialVitals,
  addPreclinic,
  handleChangeService,
} from '../../../redux/actions/presential.action';
import createAlert from '../../../redux/actions/alert.action';
import AddPresentialPatient from '../../Patient/Presential/AddPresentialPatient';
import AddVitalSigns from '../../Patient/Presential/AddVitalSigns';
import PresentialUserDetails from '../../Patient/Presential/PresentialUserDetails';
import CardDoctorCashPackageKtm from '../../Patient/Presential/CardDoctorCashPackageKtm';
import { getDoctorOffice } from '../../../api/doctor.office';
import { changeConsultDoctorOffice } from '../../../websokect/consultDoctorOfficeWebsocket';
import FollowUpAppointment from '../../Patient/Presential/FollowUpModal/FollowUpAppointment';
import {
  clearAllAppointment,
  clearSelectedAppointment,
} from '../../../redux/actions/references';
import useFarinterSelector from '../../../hooks/useFarinterSelector';
import PresalePlanCard from '../../commons/presale-plan';
import { changeStatusDoctor } from '../../../websokect/doctorWebsockets';

const FaceToFaceAppointment = ({
  getPresentialUserReference,
  addUserFlag,
  vitalFlag,
  setFalsePresentialAddUser,
  setFalsePresentialVitalSigns,
  setFalsePresentialProfile,
  complete,
  profileFlag,
  userRef,
  resetPresentialState,
  setFalsePresentialComplete,
  editVitals,
  nurseFlag,
  deletePresentialVitals,
  addPreclinic,
  presentialForm,
  token,
  handleChangeService,
  service,
  user,
}) => {
  const dispatch = useDispatch();
  const {
    reference: { selectedAppointment },
  } = useFarinterSelector();

  const [formData, setFormData] = useState({
    dni: '',
    idType: 'national',
  });

  const [idConsult, setIdConsult] = useState();
  const [preventaCode, setPreventaCode] = useState('');
  useEffect(() => {
    getDoctorOffice({ token }).then((res) => {
      setIdConsult(res.data._id);
    });

    return () => {
      resetPresentialState();
      dispatch(clearAllAppointment());
      dispatch(clearSelectedAppointment());
      changeStatusDoctor({
        doctorId: user._id,
        status: 'available',
        role: 'doctor',
      });
    };
  }, [token, resetPresentialState, dispatch, user._id]);

  const { dni, idType } = formData;

  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };
  const handleChangeServiceValue = ({ target }) => {
    handleChangeService(target.value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCancelProccess = () => {
    Swal.fire({
      title: 'Cancelar proceso',
      text: 'Sí cancela este proceso perderá todos los datos agergados en el mismo. Solo se mantendrá el usaurio si es que usted lo ha creado. ¿Está seguro que desea cancelar?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setPreventaCode('');
        setFormData({
          dni: '',
          idType: 'national',
        });
        changeStatusDoctor({
          doctorId: user._id,
          status: 'available',
          role: 'doctor',
        });
        resetPresentialState();
        dispatch(clearAllAppointment());
        dispatch(clearSelectedAppointment());
      }
    });
  };

  const handleDeletePatient = () => {
    Swal.fire({
      title: 'Creación de usuario',
      text: '¿Está seguro que desea cancelar este paso?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setFalsePresentialAddUser();
        resetPresentialState();
      }
    });
  };

  const handleDeleteProfilePatient = () => {
    Swal.fire({
      title: 'Vincular usuario',
      text: '¿Está seguro que desea cancelar este paso?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setFalsePresentialProfile();
        resetPresentialState();
        dispatch(clearAllAppointment());
      }
    });
  };

  const handleCancelVitals = () => {
    Swal.fire({
      title: 'Signos Vitales',
      text: '¿Está seguro que desea cancelar este paso?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        deletePresentialVitals();
        setFalsePresentialVitalSigns();
        setFalsePresentialComplete();
        dispatch(clearSelectedAppointment());
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const tempDni = dni.replace(/-/g, '').replace(/_/g, '');

    if (dni !== '') {
      if (
        (idType === 'national' && tempDni.length === 13) ||
        (idType === 'rtn' && tempDni.length === 14) ||
        (idType === 'foreign' && dni.length >= 8)
      ) {
        getPresentialUserReference(dni, idType);
      } else {
        createAlert('Error', 'Número de identificación incorrecto', 'error');
      }
    } else {
      createAlert('Error', 'Rellene todos los campos', 'error');
    }
  };

  const handleSubmitOrder = (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Generar orden',
      text: '¿Está seguro que desea guardar los datos de esta orden?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatusDoctor({
          doctorId: user._id,
          status: 'available',
          role: 'doctor',
        });

        // Incluye preventaCode dentro del objeto que envías a addPreclinic
        const preclinicData = {
          ...presentialForm,
          preventaCode, // Agrega preventaCode aquí
          selectedAppointment, // Incluye selectedAppointment también
        };
        setPreventaCode('');
        setFormData({
          dni: '',
          idType: 'national',
        });
        addPreclinic(preclinicData); // Pasa el objeto con todos los datos necesarios

        changeConsultDoctorOffice({ idConsult });
      }
    });
  };

  return (
    <div className='container-fluid'>
      <FollowUpAppointment />
      <div className='user-dashboard'>
        {/* Web View */}
        <div className='d-none d-sm-block'>
          <h2 className='text-primary mb-5 mt-5'>Servicios&nbsp;</h2>
        </div>
        {/* Fin Web View */}
        {/* Mobile View */}
        <div className='d-block d-sm-none'>
          <h5 className='text-primary mt-4 font-weight-bold text-center'>
            Servicios&nbsp;
          </h5>
        </div>
        {/* Fin Mobile View */}
        <div className='row'>
          <div
            className='col-md-6 col-sm-12 col-xs-12 gutter'
            style={{
              gap: '10px',
            }}
          >
            <div className='sales mb-4'>
              <div className='profile-header'>
                <h3 className='text-white text-center pt-4'>Servicios</h3>
              </div>
              <div className='container' style={{ padding: 40 }}>
                <div className='row'>
                  <div className='col-md-12 col-sm-12 col-xs-12 gutter'>
                    <h5 className='text-primary font-weight-bold mt-3 mb-4'>
                      <i className='fas fa-file-medical' />
                      &nbsp;Orden
                    </h5>
                    {!vitalFlag && !addUserFlag && !profileFlag && (
                      // muestra la ventana para buscar usuario
                      <>
                        <div className='col-md-12'>
                          <form onSubmit={handleSubmit}>
                            <div className='form-group'>
                              <label
                                className='text-secondarymdv font-weight-light'
                                style={{
                                  textAlign: 'center',
                                  width: '100%',
                                }}
                              >
                                Tipo de servicio
                              </label>
                              <select
                                className='form-control'
                                id='exampleFormControlSelect1'
                                name='service'
                                value={service}
                                placeholde='Tipo de identificación'
                                onChange={handleChangeServiceValue}
                              >
                                <option value='presential'>
                                  Cita presencial
                                </option>
                                <option value='call'>
                                  Cita mediante llamada telefónica
                                </option>
                                <option value='whatsapp'>
                                  Cita mediante Whatsapp
                                </option>
                                <option value='lab'>
                                  Servicio de laboratorio
                                </option>
                              </select>
                            </div>

                            <div className='form-group'>
                              <label
                                className='text-secondarymdv font-weight-light'
                                style={{
                                  textAlign: 'center',
                                  width: '100%',
                                }}
                              >
                                Identidad
                              </label>
                              <select
                                className='form-control'
                                id='exampleFormControlSelect1'
                                name='idType'
                                value={idType}
                                onChange={(e) => handleChange(e)}
                                placeholde='Tipo de identificación'
                              >
                                <option value='national'>ID nacional</option>
                                <option value='foreign'>ID extranjero</option>
                                <option value='rtn'>RTN</option>
                              </select>
                            </div>
                            <div className='form-group'>
                              {idType === 'foreign' ? (
                                <input
                                  type='text'
                                  className='form-control center'
                                  name='dni'
                                  value={dni}
                                  onChange={handleChange}
                                  placeholder='Identidad paciente'
                                />
                              ) : (
                                <NumberFormat
                                  className='form-control center'
                                  format={`${setFormat()}`}
                                  mask='_'
                                  name='dni'
                                  value={dni}
                                  onChange={(e) => handleChange(e)}
                                  placeholder='Identidad paciente'
                                />
                              )}
                            </div>

                            <div className='text-center'>
                              <button
                                type='submit'
                                className='btn btn-primary mb-3 font-weight-bold btn-block col-9 col-sm-5 col-md-5 mx-auto'
                                onClick={handleSubmit}
                              >
                                Generar orden
                              </button>
                            </div>
                          </form>
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {/* muestra el modal de agregar usuario  */}
                      {addUserFlag && (
                        <div
                          style={{
                            padding: 15,
                            backgroundColor: '#F9F9F9',
                            border: ' solid 1px #E2E2E2',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <h5
                            style={{
                              color: '#0192D4',
                              margin: 0,
                              fontFamily: 'sans-serif',
                              marginRight: '0 auto',
                              flex: 2,
                            }}
                            className='font-weight-bold'
                          >
                            Agregar paciente
                          </h5>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            {!vitalFlag && (
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={handleDeletePatient}
                              >
                                <TiDelete
                                  size={30}
                                  style={{ color: '#F85471' }}
                                />
                              </div>
                            )}

                            {vitalFlag && (
                              <div>
                                <IoIosCheckmarkCircle
                                  size={24}
                                  style={{ color: '#3EA845' }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {profileFlag && !addUserFlag && (
                        <div
                          style={{
                            padding: 15,
                            backgroundColor: '#F9F9F9',
                            border: ' solid 1px #E2E2E2',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <h5
                            style={{
                              color: '#0192D4',
                              margin: 0,
                              fontFamily: 'sans-serif',
                              marginRight: '0 auto',
                              flex: 2,
                            }}
                            className='font-weight-bold'
                          >
                            Vincular usuario existente
                          </h5>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            {!vitalFlag && (
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={handleDeleteProfilePatient}
                              >
                                <TiDelete
                                  size={30}
                                  style={{ color: '#F85471' }}
                                />
                              </div>
                            )}

                            {(vitalFlag || complete) && (
                              <div>
                                <IoIosCheckmarkCircle
                                  size={24}
                                  style={{ color: '#3EA845' }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {vitalFlag && (
                        <div
                          style={{
                            padding: 15,
                            backgroundColor: '#F9F9F9',
                            border: ' solid 1px #E2E2E2',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <h5
                            style={{
                              color: '#0192D4',
                              margin: 0,
                              fontFamily: 'sans-serif',
                              marginRight: '0 auto',
                              flex: 2,
                            }}
                            className='font-weight-bold'
                          >
                            Signos vitales
                          </h5>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            {!nurseFlag && (
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={handleCancelVitals}
                              >
                                <TiDelete
                                  size={30}
                                  style={{ color: '#F85471' }}
                                />
                              </div>
                            )}

                            {editVitals !== null && (
                              <div>
                                <IoIosCheckmarkCircle
                                  size={24}
                                  style={{ color: '#3EA845' }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {complete && (
                <div className='container center'>
                  <h6>{`Código de preventa (en caso de tener preventa para la consulta)`}</h6>
                  <input
                    type='text'
                    className='form-control center'
                    name='preventaId'
                    value={preventaCode}
                    onChange={(e) => setPreventaCode(e.target.value)}
                    placeholder='Código de preventa'
                  />
                  <button
                    type='submit'
                    className='btn btn-primary mt-4 mb-3 col-12 col-md-4'
                    onClick={handleSubmitOrder}
                    style={{ marginLeft: 5, marginRight: 5 }}
                  >
                    Finalizar Proceso
                  </button>
                  <button
                    type='submit'
                    className='btn btn-danger mt-4 mb-3 col-12 col-md-4'
                    onClick={handleCancelProccess}
                    style={{
                      marginLeft: 5,
                      marginRight: 5,
                      borderRadius: '2rem',
                    }}
                  >
                    Cancelar Proceso
                  </button>
                </div>
              )}
              {!complete && vitalFlag && (
                <div className='container center'>
                  <button
                    type='submit'
                    className='btn btn-primary mt-4 mb-3 mr-auto col-12 col-md-4'
                    onClick={handleCancelProccess}
                  >
                    Cancelar Proceso
                  </button>
                </div>
              )}
            </div>
            {(vitalFlag || addUserFlag || profileFlag) &&
              !complete &&
              profileFlag &&
              userRef !== null &&
              !addUserFlag &&
              !vitalFlag && <CardDoctorCashPackageKtm />}
            {user?.role === 'checker' && (
              <div style={{ marginTop: 10 }}>
                {(vitalFlag || addUserFlag || profileFlag) &&
                  !complete &&
                  profileFlag &&
                  userRef !== null &&
                  !addUserFlag &&
                  !vitalFlag && <PresalePlanCard />}
              </div>
            )}
          </div>

          {(vitalFlag || addUserFlag || profileFlag) && !complete && (
            <div className='col-md-6 col-sm-12 col-xs-12 gutter'>
              <div className='sales report marb3'>
                {addUserFlag && userRef == null && (
                  <AddPresentialPatient id={dni} type={idType} />
                )}
                {profileFlag &&
                  userRef !== null &&
                  !addUserFlag &&
                  !vitalFlag && <PresentialUserDetails />}
                {vitalFlag && <AddVitalSigns />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  addUserFlag: state.presential.addUserFlag,
  vitalFlag: state.presential.vitalFlag,
  complete: state.presential.complete,
  profileFlag: state.presential.profileFlag,
  userRef: state.presential.user,
  editVitals: state.presential.preClinic.vitalSings,
  presentialForm: state.presential,
  token: state.auth.token,
  service: state.presential.service,
});

export default connect(mapStateToProps, {
  getPresentialUserReference,
  setFalsePresentialAddUser,
  setFalsePresentialPhysicalExam,
  setFalsePresentialVitalSigns,
  setFalsePresentialProfile,
  resetPresentialState,
  setFalsePresentialComplete,
  deletePresentialPhysical,
  deletePresentialNurseDetails,
  setFalsePresentialNurseDetails,
  deletePresentialVitals,
  addPreclinic,
  handleChangeService,
})(FaceToFaceAppointment);
