import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  addSurgeries,
  deleteItemSurgeries,
  getSurgeries,
  updateSurgeries,
} from '../../../redux/actions/surgeries.action';
import { MdCancel } from 'react-icons/all';
import './history.css';

function Surgeries({
  addSurgeries,
  selectedItem,
  deleteItemSurgeries,
  updateSurgeries,
  getSurgeries,
  userRef,
}) {
  const [formData, setFormData] = useState({
    name: '',
    year: '',
  });

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        name: selectedItem.name,
        year: selectedItem.year,
      });
    } else {
      setFormData({
        name: '',
        year: '',
      });
    }
  }, [selectedItem]);

  const { name, year } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addSurgeries(formData, userRef?._id);
    setFormData({ name: '', year: '' });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    updateSurgeries(selectedItem._id, formData);
    deleteItemSurgeries();
    getSurgeries();
  };

  return (
    <div>
      <div className='sales mt-3'>
        <div className='media'>
          <div className='media-body'>
            <h5 className='font-blue mt-3 text-center'>
              Agregar Antecedentes quirúrgicos
            </h5>
            <form className='p-4'>
              <div className='form-group'>
                <label> Tipo de cirugía</label>
                <input
                  type='text'
                  className='form-control center m-auto col-12 col-md-7'
                  name='name'
                  value={name}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className='form-group'>
                <label>Año</label>
                <input
                  type='text'
                  className='form-control center m-auto col-12 col-md-7'
                  name='year'
                  value={year}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {selectedItem !== null ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    className='btn btn-primary update-button-medicine'
                    onClick={handleUpdate}
                  >
                    ACTUALIZAR
                  </button>
                  <button
                    className='button-medicines'
                    onClick={() => deleteItemSurgeries()}
                  >
                    <MdCancel className='cancel-update-buttons' />
                  </button>
                </div>
              ) : (
                <div className='form-group mt-3'>
                  <button
                    className='btn btn-primary btn-block m-auto col-12 col-md-7'
                    onClick={handleSubmit}
                  >
                    AGREGAR
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  surgeries: state.history.surgeries.items,
  selectedItem: state.history.surgeries.selectedItem,
  userRef: state.reference.userRef,
});

export default connect(mapStateToProps, {
  addSurgeries,
  getSurgeries,
  deleteItemSurgeries,
  updateSurgeries,
})(Surgeries);
